import React, { useEffect, useRef, useState } from "react";
import ReactMapGL from "react-map-gl";
import Geocoder from "react-mapbox-gl-geocoder";
import styled from "styled-components";
import media from "styled-media-query";
import Footer from "../components/footer.js";
import Header from "../components/Header";
// import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import { MaxGrid } from "../components/Theme";
const Input = styled.input`
  width: 240px;
  padding: 10px;
  border: 0px;
  border-radius: 2px;
  background-color: #f7f7f7;
`;
const SearchBox = styled(Geocoder)`
  top: 15px;
  left: 15px;
  z-index: 999;
  max-width: 300px;
  background: #fff;
  padding: 5px;
  position: absolute;
  border-radius: 2px;
  input {
    border: 0px;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAACU0lEQVR4Xu2aQYQbYRTHm24vw1CGEkpPpaRKqysrp63ULlWWkFMP1bKn6mHppedeeiqp9lSih0q1tpftqbo6WkJsNbosuYZlWUroKZRIfyWHz5+kk0wyj/UNP8+7PPnlveebmSQ3HA5PnYTrNJwUES/iRbyIF/EiXsSLnJlGIJfLlQgbUIYrEEAfDiCGj9CaVoDbpGxEECgQarAGegVQHPEYdmELOtajpRJVwp5ITGIN9qBqKaISFcI2hCLQhVcj6nAoMiFsQ8VqR3ScGiKwDw+Y6ZYI/wtleAEFR6gBy9Cx7EgNAif/ACuOhBLDMnyS/amZjRbdWJWdOIJNJP6IgNKHu9CTnVm16sgdye8j8TthzV+wpfVMROQbPEBid8q6b6UrZh254CTtGeoOoCX1shVhP0JZ8t6MtXuy9GHWHenDwMnPzSgSSYf6mYqwDwNCN+V8L0FJDtCBxY58d+ebcStPKVKBSOtZiNQlf4ZMkFDiLDzVeiYijFdMaDoiV+EdMmECiR246Eg0Iba8RXkE7km+AT+QuT1Gogo/QXcqgvyiRSa++6UD9wivx5zeLTiG81CSnVA6cBOOx0zAQkRU5iGhBkuQ4hIZEVn4gxWiLwnrsJ9Q4NuYQ7QAXyCf6Y7o8sM1klvwHNrOhz2EJjyBFbgB61nLpPl9RB+slOvwGaJJY5bBaKWm/Z/OfJXOpBcxkrkkMulFrGUYzfx8ROxlduYjYi9TpCtRehF7mTecV73UIkYyR6P8PWzO5QWdkcxliKA7p3st/4cBL+JFvIgX8SJe5C+JA7GR8loW+wAAAABJRU5ErkJggg==");
    background-size: 20px auto;
    background-position: center left;
    background-repeat: no-repeat;
    font: 400 12px am, sans-serif;
    text-transform: uppercase;
    padding-left: 24px;
  }
  .react-geocoder-item {
    cursor: pointer;
    font: 400 12px am, sans-serif;
    text-transform: uppercase;
    margin-top: 10px;
  }
  .react-geocoder-item:hover {
    text-decoration: underline;
  }
`;

const CustomOrderGrid = styled.section`
  ${MaxGrid};
  background-color: #fff;
  align-self: middle;
  padding-top: 0px;
  padding-bottom: 60px;
  input {
    display: block;
  }

  h1 {
    margin-bottom: 20px;
    padding-top: 40px;
  }
  p.desc {
    font-family: nhp;
    font-size: 16px;
    width: 70%;
    opacity: 0.4;
    margin-bottom: 40px;
  }
  .caption.form-heading {
    margin-bottom: 10px;
    margin-top: 20px;
    font-size: 12px;
    opacity: 0.4;
  }

  .createprint {
    height: 48px;
    line-height: 48px;
    padding: 0 20px;
    background-color: #000;
    color: #fff;
    margin-top: 40px;
    width: 240px;
    border-radius: 2px;
  }
  .mapboxgl-ctrl-bottom-left,
  .mapboxgl-ctrl-bottom-right {
    z-index: -1 !important; // This is 1000 by default
    position: absolute;
    pointer-events: none;
  }
  .w-select {
    width: 240px;
  }
`;

const MapWrapper = styled.div`
  grid-column: 2/8;
  padding: 60px 240px 60px 0;
  align-self: middle;
  align-items: center;
  align-content: center;
  align-items: middle;
  ${media.lessThan("medium")`
        padding: 20px 10px 40px 10px; 
  `}
`;
const MapContainer = styled.div`
  width: 100%;
  position: relative;
  align-self: middle;
  box-shadow: 0 0 0 20px #fcfcfc, -10px 20px 30px 5px rgba(0, 0, 0, 0.4),
    0 0 0 22px rgba(0, 0, 0, 0.1);
  align-content: middle;
  align-items: middle;
  ${media.lessThan("large")`
        box-shadow: 0 0 0 10px #fcfcfc, -5px 10px 20px 5px rgba(0, 0, 0, 0.2),
        0 0 0 12px rgba(0, 0, 0, 0.1);
    `}
`;
const DetailsContainer = styled.div`
  grid-column: 8/13;

  padding-top: 0px;
  h1 {
    margin-top: 0;
    padding-top: 0;
  }
`;

const queryParams = {
  // country: "us"
};

const PRINT_SIZES = ["250x250", "200x250", "250x200"];

const PRINT_SIZES_DATA_ARR = PRINT_SIZES.map((size, index) => {
  const [width, height] = size.split("x");
  let name;
  if (index === 0) {
    name = "Square";
  }
  if (index === 1) {
    name = "Horizontal";
  }
  if (index === 2) {
    name = "Vertical";
  }

  return {
    label: name,
    value: size,
    width,
    height,
  };
});

const Explore = () => {
  const [selectedPrintSize, setPrintSize] = useState(PRINT_SIZES[0]);
  const [viewport, setViewport] = useState({
    latitude: 45.4211,
    longitude: -75.6903,
    width: "80%", // update value based on print selector size?
    height: "10vh", // update value based on print selector size?
    zoom: 0,
  });

  const mapContainerRef = useRef(null);
  useEffect(() => {
    const refWidth = mapContainerRef.current
      ? mapContainerRef.current.offsetWidth
      : 0;

    const selectedSizeIndex = PRINT_SIZES_DATA_ARR.findIndex(
      (sizeObj) => sizeObj.value === selectedPrintSize
    );

    let ratio;

    if (selectedPrintSize !== -1) {
      const { width, height } = PRINT_SIZES_DATA_ARR[selectedSizeIndex];
      ratio = height / width;
    }

    const refHeight = refWidth && ratio ? refWidth / ratio : 0;
    setViewport({
      ...viewport,
      width: refWidth,
      height: refHeight,
    });
  }, [mapContainerRef.current, selectedPrintSize]);

  const { longitude, latitude, zoom, bearing, pitch, width, height } = viewport;

  return (
    <Layout>
      <Header content_color="black" />

      <CustomOrderGrid>
        <MapWrapper>
          <MapContainer ref={mapContainerRef}>
            <SearchBox
              mapboxApiAccessToken="pk.eyJ1IjoiZ3JlZ2NvcmJ5IiwiYSI6ImNrMDA5bXlxejAxZGYzbW40cXlpaGxwOTQifQ.208zF_2QfWCxkI6qrj5dnw"
              onSelected={(viewport) => {
                setViewport(viewport);
              }}
              viewport={viewport}
              hideOnSelect={true}
              queryParams={queryParams}
            />
            <ReactMapGL
              {...viewport}
              mapboxApiAccessToken="pk.eyJ1IjoiZ3JlZ2NvcmJ5IiwiYSI6ImNrMDA5bXlxejAxZGYzbW40cXlpaGxwOTQifQ.208zF_2QfWCxkI6qrj5dnw"
              mapStyle="mapbox://styles/mapbox/satellite-v9"
              onViewportChange={(viewport) => {
                setViewport(viewport);
              }}
            ></ReactMapGL>
          </MapContainer>
        </MapWrapper>
        <DetailsContainer>
          <h1 className="sub-headline">Custom Print Creator</h1>
          <p className="desc">
            Search for a location. <br />
            Click and drag the map to zoom / crop. <br />
            Hold Ctrl while exploring to rotate the frame.
            <br />
          </p>
          <form
            method="POST"
            action="https://formspree.io/meqqpqdv"
            // onSubmit={e => {
            //   e.preventDefault();
            //   console.log("viewport", viewport);
            // }}
          >
            <input
              type="hidden"
              name="static-map-image-link"
              value={`https://api.mapbox.com/styles/v1/mapbox/satellite-v9/static/${longitude},${latitude},${zoom},${bearing},${pitch}/${width}x${height}?access_token=pk.eyJ1IjoiZ3JlZ2NvcmJ5IiwiYSI6ImNrMDA5bXlxejAxZGYzbW40cXlpaGxwOTQifQ.208zF_2QfWCxkI6qrj5dnw`}
            />
            <input type="hidden" name="latitude" value={latitude} />
            <input type="hidden" name="longitude" value={longitude} />

            <input type="hidden" name="zoom" value={zoom} />
            <input type="hidden" name="bearing" value={bearing} />
            <input type="hidden" name="pitch" value={pitch} />
            <input type="hidden" name="width" value={width} />
            <input type="hidden" name="height" value={height} />
            <label className="caption form-heading">
              Preferred Orientation
            </label>
            <select
              className="caption w-select"
              name="Preferred Orientation"
              value={selectedPrintSize}
              onChange={(e) => setPrintSize(e.target.value)}
            >
              {PRINT_SIZES_DATA_ARR.map(({ value, label }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </select>
            <label className="caption form-heading">Your Name</label>
            <Input type="text" name="Name: " placeholder="Your Name" />
            <label className="caption form-heading">Your Email</label>
            <Input type="text" name="Email: " placeholder="Your Email" />

            <input
              type="submit"
              className="createprint"
              value="Submit Custom Print Request"
            />
          </form>
        </DetailsContainer>
      </CustomOrderGrid>

      <Footer />
    </Layout>
  );
};

export default Explore;
